import { useEffect, useState } from "react";
import HeaderSlider from "./HeaderSlider";
import AxiosInstance from "../Axios";
import { CFormSelect } from "@coreui/react";
import { Card } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion'
import MyToastify from 'src/components/myComponents/MyToactify';
import CIcon from '@coreui/icons-react';
import { cilTrash } from '@coreui/icons';
import { CButton } from '@coreui/react';

const AddHeaderSlider = ({id, newSlider, parentUrl, infosId}) => {

    const [sliders, setSliders] = useState([]);
    const [defaultId, setDefaultId] = useState(id);
    const [defaultSliders, setDefaultSliders] = useState(false);
    const popup = MyToastify();

    useEffect(() => {
        AxiosInstance.get('/sliders').then((response) => {
            if(response.status == 200)
                setSliders(response.data['hydra:member']);
        });   
    }, []);

    useEffect(() => {
        setDefaultId(id);
        if(id != undefined)
            AxiosInstance.get(`/sliders/${id}`).then((response) => {
                if(response.status == 200)
                    setDefaultSliders(response.data);
            });
    }, [id]);

    // useEffect(() => {
    //    console.log(defaultSliders);
    //    console.log(sliders.map((v) => ({value:v.id, label:v.name})));
        
    // }, [defaultSliders]);

    const setNewSlider = (val) => {
        if (parentUrl !== undefined) {
            const response = AxiosInstance.put(parentUrl, {slider:`/api/sliders/${val}`}).then(()=>{
                popup.toastifySuccess('Слайдер додано');
            });
        }
        if (infosId !== undefined) {
            const response = AxiosInstance.put(`/page_infos/${infosId}`, {slider:`/api/sliders/${val}`}).then(()=>{
                popup.toastifySuccess('Слайдер-модуль додано');
            });
        }
        setDefaultId(val);
        newSlider(val);
    }

    const removeSlider = () => {
        if (parentUrl !== undefined) {
            const response = AxiosInstance.put(parentUrl, {slider: null}).then(()=>{
                popup.toastifySuccess('Слайдер видалено');
            });
        }
        if (infosId !== undefined) {
            const response = AxiosInstance.put(`/page_infos/${infosId}`, {slider: null}).then(()=>{
                popup.toastifySuccess('Слайдер-модуль видалено');
            });
        }
        setDefaultSliders(false);
        newSlider(false);
    }

    return(
        <>
         <Accordion className="mb-3">
            <Accordion.Item eventKey="0">
                <Accordion.Header>Слайдер</Accordion.Header>
                <Accordion.Body className="p-5">
                    <div className="row">
                    {(defaultId !== undefined && defaultId !== false) &&
                        <div className="col">
                          <HeaderSlider id={defaultId}/>
                        </div>}
                        <div className="col">
                            <CFormSelect
                                aria-label="Default select example"
                                onChange={(e) => setNewSlider(e.target.value)}
                                value={defaultSliders?.id}
                                options={sliders.map((v) => ({value:v.id, label:v.slug}))}
                            />
                            <div className="row">
                                <div className="col">
                                    {(defaultSliders?.id != undefined) ? <a href={`/slider/update/${defaultSliders.id}`}  target="_blanck">Оновити слайдер</a> : ''}
                                    
                                </div>
                                <div className="col d-flex justify-content-end">
                                    <a href="/slider/create" target="_blanck">Створити новий слайдер</a>
                                </div>
                            </div>
                            {(defaultSliders?.id != undefined) && 
                            <CButton className="mt-2" color="danger" variant="outline" onClick={() => removeSlider()}>
                                  <CIcon icon={cilTrash} customClassName="nav-icon" height={20} />
                            </CButton>}
                        </div>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
            
        
        
        </>


    );
}

export default AddHeaderSlider;