import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Link, useLoaderData } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { useNavigate } from "react-router-dom";
import { CForm, CFormLabel, CFormInput, CButton } from '@coreui/react';
import AsyncSelect from 'react-select/async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import Select from 'react-select';
// import MediaBox from '../media/MediaBox';
import MediaFrom from '../../components/media/MediaFrom';

const urlApi = '/accounts';
const urlApi_2 = '/agreements';
const urlRouter = '/accounts';
const tokenStr = localStorage.getItem('user');
const header = { headers: { "Authorization": `Bearer ${tokenStr}`, 'accept': 'application/json' } };
const userData = JSON.parse(localStorage.getItem('user_data'));
const username = localStorage.getItem('user_name');



const AccountsFormComponents = ({ data, type, setShowModalCreateAccount, setNewAccountModal, setNewAgreementModal, setIsUpdateOrder, allowUpdateOrder }) => {
  // console.log('data', data);

  const AccountsType = process.env.REACT_APP_ACCOUNTS_TYPE;
  const SYSTEM_TYPE = process.env.REACT_APP_SYSTEM_TYPE;
  console.log(AccountsType);
  const [typeArr, setTypeArr] = useState(() => {
    if(AccountsType != undefined)
      return JSON.parse(AccountsType);
    else
      return [
        { value: 'Партнер', label: 'Партнер' },
        { value: 'Фінансування', label: 'Фінансування' },
      ];

  });

  const [myData, setMyData] = useState((data != undefined) ? data : "");
  // const [newMedia, setNewMedia] = useState(false);
  const [media, setMedia] = useState((data != undefined) && data.media?.id);
  const [mediaUrl, setMediaUrl] = useState(false);

  const navigate = useNavigate();
  const [name, setName] = useState((myData != "") ? myData.name : '');
  const [shortName, setShortName] = useState((myData != "") ? myData.shortName : '');
  const [description, setDescription] = useState((myData != "") ? myData.description : '');
  const [phone, setPhone] = useState((myData != "") ? myData.phone : '');
  const [isExistPhone, setIsExistPhone] = useState(false); // чи вже є в базі номер телефону
  const [user, setUser] = useState('');
  const [agreements, setAgreements] = useState([]); // всі договори контрагента
  const [isAgreementKasa, setIsAgreementKasa] = useState(false); // чи в контрагента є договір "Каса"
  const [isAgreementBank, setIsAgreementBank] = useState(false); // чи в контрагента є договір "Банк"


  // const [manager, setManager] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedManager, setSelectedManager] = useState(null);
  const [selectedType, setSelectedType] = useState(
    {
      label: (myData != "" && myData.type != undefined) ? myData.type : typeArr[0].label,
      value: (myData != "" && myData.type != undefined) ? myData.type : typeArr[0].value,
    }
  );

  // const [ipn, setIpn] = useState((myData != "") ? myData.ipn : '');
  const [typeAcc, setTypeAcc] = useState((myData != "" && type == undefined) ? myData.type : typeArr[0].label);
  const [edrpou, setEdrpou] = useState((myData != "") ? myData.edrpou : '');
  const [isExistEdrpou, setIsExistEdrpou] = useState(false); // чи вже є в базі код ЄДРПОУ

  // const [parent, setParent] = useState((myData != "") ? myData.parent : '');
  // const [director, setDirector] = useState((myData != "") ? myData.director : '');
  // const [code1c, setCode1c] = useState((myData != "") ? myData.code1c : '');
  const [manager, setManager] = useState((myData != "") ? myData.manager : '');
  const editorRef = useRef(null);
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupTextPostSuccess = "Контрагента створено";
  const popupTextPutSuccess = "Контрагента оновлено";
  const popupTextCreateAgreementKasa = "Договір \"Каса\" створено";
  const popupTextCreateAgreementBank = "Договір \"Банк\" створено";
  const popupTextEDRPOUWarning = "Введений код ЄДРПОУ в базі вже існує";
  const popupTextPhoneWarning = "Введений номер телефону в базі вже існує";



  const getUserName = (user) => {
    let fn = (user.firstName != undefined) ? `${user.firstName} ` : '';
    let ln = (user.lastName != undefined) ? `${user.lastName} ` : '';
    let un = (user.username != undefined) ? ` (${user.username})` : '';
    return `${fn}${ln}${un}`;
  };

  if (myData != "" && myData.user != undefined && user == '') {
    setUser(`/api/users/${myData.user.id}`);
    setSelectedOption({
      label: getUserName(myData.user),
      value: myData.user.id,
    });
  };




  useEffect(() => {
    // if (myData != "" && myData.manager != undefined && manager == '') {
    if (myData != "" && myData.manager != undefined) {
      setManager(`/api/users/${myData.manager.id}`);
      setSelectedManager({
        label: getUserName(myData.manager),
        value: myData.manager.id,
      });
    } else if (userData && username) {
      let user = {
        firstName: userData.first_name,
        lastName: userData.last_name,
        username: username
      }
      setManager(`/api/users/${userData.id}`);
      setSelectedManager({
        label: getUserName(user),
        value: userData.id,
      });
    };
  }, [myData, userData]);

  // Функція для запиту за данити контрагента
  const getAccountData = (id) => {
    AxiosInstance.get(`${urlApi}/${id}`, header).then((response) => {
      // console.log('get accounts/id response', response);
      if (response.status === 200) {
        setMyData(response.data);
      };
    }).catch((error) => {
      console.error("Помилка запиту за даними контрагента", error);
    });
  };

  // Для запобігання оновлення замовлення при створенні контрагента, коли контрагент створюється з сторінки замовлення
  useEffect(() => {
    if (setIsUpdateOrder != undefined) {
      setIsUpdateOrder(false);
    }
  }, []);

  useEffect(() => {
    // console.log(media);
    if (media != undefined) {
      AxiosInstance.get(`/media_objects/${media}`).then((response) => {
        if (response.status == 200) {
          setMediaUrl(response.data.contentUrl)
        }
      });
    }
  }, [media]);

  

  // Функція для створення контрагента
  const createAccount = (data_response) => {
    // console.log('data_response', data_response);
    AxiosInstance.post(`${urlApi}`, data_response, header).then((response) => {
      if (response.status === 201) {
        // Popup про успішні зміни
        popup.toastifySuccess(popupTextPostSuccess);
        // Створити договір
        // createAgreementKasa(data_response, response.data.id);

        if (type != undefined && type === "create") {
          setNewAccountModal(
            {
              name: response.data.name,
              id: response.data.id
            },
          );

          // Для запобігання оновлення замовлення при створенні контрагента, коли контрагент створюється з сторінки замовлення
          allowUpdateOrder();
        } else {
          setMyData(response.data);
          return navigate(`${urlRouter}/update/${response.data.id}`);
        };
      };
    });
  };

  // Функція для оновлення контрагента
  const updateAccount = (data_response) => {
    AxiosInstance.put(`${urlApi}/${myData.id}`, data_response, header).then((response) => {
      // console.log('put account response', response);
      if (response.status === 200) {
        // Popup про успішні зміни
        popup.toastifySuccess(popupTextPutSuccess);
        setMyData(response.data);
        // return navigate(urlRouter);
      }
    });
  };

  // Функція для створення договору "Каса"
  const createAgreementKasa = (myData, accountId) => {
    const data_response = {
      name: `${myData.name} (Каса)`,
      account: `/api/accounts/${accountId}`,
      typePayment: "kasa",
    };

    // console.log('createAgreementKasa data_response', data_response);
    AxiosInstance.post(`${urlApi_2}`, data_response, header).then((response) => {
      if (response.status === 201) {
        if (type != undefined && type === "create") {
          setNewAgreementModal({
            name: response.data.name,
            id: response.data.id,
            typePayment: response.data.typePayment
          });
          // Закрити модальне вікно створення контрагента
          setShowModalCreateAccount(false);
        } else if (myData !== "" && type != undefined && type === "accountPage") {
          // popup.toastifySuccess(popupTextPutSuccess);
          popup.toastifySuccess(popupTextCreateAgreementKasa);
          // Оновити дані контрагента
          getAccountData(accountId);
        }
      } else {
        console.log('Помилка запиту');
      };
    });
  };

  // Функція для створення договору "Банк"
  // const createAgreementBank = (myData, accountId) => {
  const createAgreementBank = () => {
    const data_response = {
      name: `${myData.name} (Банк)`,
      // account: `/api/accounts/${accountId}`,
      account: `/api/accounts/${myData.id}`,
      typePayment: "bank",
      // active: 'on',
    };

    // console.log('createAgreementKasa data_response', data_response);
    AxiosInstance.post(`${urlApi_2}`, data_response, header).then((response) => {
      if (response.status === 201) {
        if (type != undefined && type === "accountPage") {
          // popup.toastifySuccess(popupTextPutSuccess);
          popup.toastifySuccess(popupTextCreateAgreementBank);
          // Оновити дані контрагента
          // getAccountData();
          getAccountData(myData.id);
        }
      } else {
        console.log('Помилка запиту');
      };
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    let v = true;
    let vError = '';

    if (manager == '') {
      v = false;
      vError += "-> Менеджер\n";
    }

    if (!v) {
      alert("Ви не заповнили обовязкові поля:\n" + vError);
    }

    if (v && editorRef.current) {
      // console.log(editorRef.current.getContent());
      let data_response = {
        name: name,
        shortName: shortName,
        description: description,
        edrpou: edrpou,
        phone: phone,
        // ipn: ipn,
        type: typeAcc,
        // parent: parent,
        // director: director,
        // code1c: code1c,
        media: (media && media !== undefined) ? `/api/media_objects/${media}` : null,
        manager: manager,
        // user: user,
        active: 'on',
      };

      if (user != "") {
        data_response["user"] = user;
      }

      // Додаткова перевірка по коду ЄДРПОУ та номеру телефона
      Promise.all([
        checkEdrpou(),
        checkPhone()
      ]).then(([isEdrpouValid, isPhoneValid]) => {
        // console.log('isEdrpouValid', isEdrpouValid);
        // console.log('isPhoneValid', isPhoneValid);

        if (isEdrpouValid && isPhoneValid) {
          // console.log("Надіслати форму");
          // Коли на момент створення замовлення не було створено контрагента
          if (type != undefined && type === "create") {
            // console.log("type === create");
            createAccount(data_response);
          } else if (myData != "") {
            // console.log("put");
            updateAccount(data_response);
          } else {
            // console.log("post");
            createAccount(data_response);
          };
        } else {
          let text = "";
          if (!isEdrpouValid) {
            text += "Код ЄДРПОУ."
          };
          if (!isPhoneValid) {
            text += " Номер телефону."
          };
          console.log(`Для надсилання форми правильно заповніть поля: ${text}`);
        };
      });
    };
  };

  const loadOptions = async (inputValue) => {
    return await AxiosInstance.get(`/users?firstName=${inputValue}&lastName=${inputValue}&username=${inputValue}`, { headers: { 'accept': 'application/json' } }).then((response) => {
      return response.data.map((result) => ({
        label: getUserName(result),
        value: result.id,
      }));
    });
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setUser(`/api/users/${selectedOption.value}`);
  };

  // Для типу особи
  const handleChange_2 = (value) => {
    setSelectedType(value);
    setTypeAcc(value.value);
  };

  const handleChangeManagerr = (selectedOption) => {
    setSelectedManager(selectedOption);
    setManager(`/api/users/${selectedOption.value}`);
  };

  // Функція для запиту за договорами
  const getAgreements = (id) => {
    // account.id
    AxiosInstance.get(`${urlApi_2}?account.id=${id}`, header).then((response) => {
      // console.log('get agreements response', response);
      if (response.status === 200) {
        setAgreements(response.data);
      };
    }).catch((error) => {
      console.error("Помилка запиту для отримання договорів по контрагенту:", error);
    });
  };

  // Функція для перевірки чи в контрагента є договір "Каса"
  const checkAgreementKasa = () => {
    const isKasa = agreements.some(item => item.typePayment === "kasa");
    setIsAgreementKasa(isKasa);
  };

  // Функція для перевірки чи в контрагента є договір "Каса"
  const checkAgreementBank = () => {
    const isBank = agreements.some(item => item.typePayment === "bank");
    setIsAgreementBank(isBank);
  };

  // Зробити запит за договорами
  useEffect(() => {
    // console.log('myData', myData);
    if (myData != "" && type === "accountPage") {
      getAgreements(myData.id);
    }
  }, [myData]);

  // Перевірити чи є договори "Каса" і "Банк"
  useEffect(() => {
    // console.log('agreements', agreements);
    checkAgreementBank();
    checkAgreementKasa();
  }, [agreements]);

  // Функція для перевірки коду ЄДРПОУ
  const checkEdrpou = async () => {
    // console.log('myData.edrpou', myData.edrpou);
    // console.log("onBlur edrpou", edrpou);
    if ((myData?.edrpou && myData.edrpou === edrpou) || edrpou === "") {
      // console.log('myData?.edrpou && myData.edrpou === edrpou) || edrpou === ""');
      setIsExistEdrpou(false);
      return true; // для перевірки чи можна надсилати форму
    } else {
      try {
        const isExist = await checkEdrpouInApi();
        // console.log('isExist', isExist);
        // debugger
        setIsExistEdrpou(isExist);
        return !isExist; // для перевірки чи можна надсилати форму
      } catch (error) {
        console.error('Помилка перевірки на api коду ЄДРПОУ: ', error);
        setIsExistEdrpou(true);
        return false; // для перевірки чи можна надсилати форму
      };
    };
  };

  // Функція для перевірки чи вже є в базі код ЄДРПОУ
  const checkEdrpouInApi = async () => {
    try {
      const response = await AxiosInstance.get(`${urlApi}?edrpou=${edrpou}`);
      // console.log('get edrpou response', response);
      if (response.data['hydra:totalItems'] !== 0) {
        // console.log('hydra:totalItems', response.data['hydra:totalItems']);
        popup.toastifyWarning(popupTextEDRPOUWarning);
        return true; // код ЄДРПОУ в базі вже існує
      } else {
        // console.log('hydra:totalItems', response.data['hydra:totalItems']);
        return false; // код ЄДРПОУ в базі ще не введений
      };
    } catch (error) {
      console.log("Помилка перевірки на api коду ЄДРПОУ: ", error);
      return true; // код ЄДРПОУ в базі вже існує
    };
  };

  // Функція для перевірки номера телефону
  const checkPhone = async () => {
    // console.log('myData.phone', myData.phone);
    // console.log("onBlur phone", phone);
    if ((myData?.phone && myData.phone === phone) || phone === "") {
      // console.log('myData?.phone && myData.phone === phone) || phone === ""');
      setIsExistPhone(false);
      return true; // для перевірки чи можна надсилати форму
    } else {
      try {
        const isExist = await checkPhoneInApi();
        // console.log('isExist', isExist);
        // debugger
        setIsExistPhone(isExist);
        return !isExist; // для перевірки чи можна надсилати форму
      } catch (error) {
        console.error('Помилка перевірки на api номера телефону: ', error);
        setIsExistPhone(true);
        return false; // для перевірки чи можна надсилати форму
      };
    };
  };

  // Функція для перевірки чи вже є в базі номер телефону
  const checkPhoneInApi = async () => {
    try {
      const response = await AxiosInstance.get(`${urlApi}?phone=${phone}`);
      // console.log('get phone response', response);
      if (response.data['hydra:totalItems'] !== 0) {
        // console.log('hydra:totalItems', response.data['hydra:totalItems']);
        popup.toastifyWarning(popupTextPhoneWarning);
        return true; // номер телефону в базі вже існує
      } else {
        // console.log('hydra:totalItems', response.data['hydra:totalItems']);
        return false; // номер телефону в базі ще не введений
      }
    } catch (error) {
      console.log("Помилка перевірки на api номер телефону: ", error);
      return true; // номер телефону в базі вже існує
    };
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />
      {(mediaUrl && mediaUrl != undefined) ? <img className="mb-3 object-fit-cover" width={250} height={150} src={process.env.REACT_APP_SERVER_URL + mediaUrl} /> : ''}
        <div className="mb-3">
          <MediaFrom type="category" setNewMediaId={setMedia} toastifySuccess={popup.toastifySuccess} />
      </div>

      {/* {(myData != undefined && myData != '') && <MediaBox default_media={myData.media} setNewMedia={setNewMedia}/> } */}

      {/* Кнопки для створення договорів */}
      {
        (myData != "" && type === "accountPage") &&
        <div>
          {
            (!isAgreementKasa && SYSTEM_TYPE == 'GURT') &&
            <CButton
              className="mb-3 me-3" color="secondary"
              onClick={() => createAgreementKasa(myData, myData.id)} title={`Створити для контрагента "${myData.name}" договір "Каса"`}
            >
              Створити договір Каса
            </CButton>
          }
          {
            (!isAgreementBank && SYSTEM_TYPE == 'GURT') &&
            <CButton
              className="mb-3" color="secondary"
              onClick={() => createAgreementBank()} title={`Створити для контрагента "${myData.name}" договір "Банк"`}
            >
              Створити договір Банк
            </CButton>
          }
        </div>
      }

      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва*</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" required />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Коротка Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={shortName} onChange={(e) => setShortName(e.target.value)} autoComplete="off"/>
        </div>
        <div className="mb-3" style={{ zIndex: "102", position: "relative" }}>
          <CFormLabel htmlFor="exampleFormControlInput6">Тип</CFormLabel>
          <Select options={typeArr} value={selectedType} onChange={(value) => { handleChange_2(value) }} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput3">Код ЄДРПОУ</CFormLabel>
          <CFormInput
            type="text" id="exampleFormControlInput3"
            value={edrpou} onChange={(e) => setEdrpou(e.target.value)}
            onBlur={() => checkEdrpou()}
            autoComplete="off"
            style={{ borderColor: isExistEdrpou ? "red" : "" }}
          />
          {isExistEdrpou && <span style={{ color: "red" }}>{popupTextEDRPOUWarning}</span>}
        </div>
        {(SYSTEM_TYPE == 'GURT' || SYSTEM_TYPE == 'SHOP') && 
          <div className="mb-3" style={{ zIndex: "101", position: "relative" }}>
            <CFormLabel htmlFor="exampleFormControlInput1">Користувач (Клієнт)</CFormLabel>
            <AsyncSelect
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions
              value={selectedOption}
              onChange={handleChange}
              className="flex-grow-1"
              placeholder="Виберіть..."
            />
          </div>
        }
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Опис</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={description} onChange={(e) => setDescription(e.target.value)} autoComplete="off" />
        </div>
        {/* <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput4">Телефон*</CFormLabel>
          <CFormInput
            type="text" id="exampleFormControlInput4"
            value={phone} onChange={(e) => setPhone(e.target.value)}
            onBlur={() => checkPhone()}
            style={{ borderColor: isExistPhone ? "red" : "" }}
            autoComplete="off" required
          />
          {isExistPhone && <span style={{ color: "red" }}>{popupTextPhoneWarning}</span>}
        </div> */}
        {/* <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput5">ipn</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput5" value={ipn} onChange={(e) => setIpn(e.target.value)} autoComplete="off" />
          </div> */}
        {/* <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput6">Тип</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput6" value={type} onChange={(e) => setType(e.target.value)} autoComplete="off" />
          </div> */}
        {/* <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput7">parent</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput7" value={parent} onChange={(e) => setParent(e.target.value)} autoComplete="off" />
          </div> */}
        {/* <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput8">director</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput4" value={director} onChange={(e) => setDirector(e.target.value)} autoComplete="off" />
          </div> */}
        {/* <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput8">code1c</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput6" value={code1c} onChange={(e) => setCode1c(e.target.value)} autoComplete="off" />
          </div> */}
        {/* <div className="mb-3">
            <CFormLabel htmlFor="exampleFormControlInput9">Менеджер</CFormLabel>
            <CFormInput type="text" id="exampleFormControlInput9" value={manager} onChange={(e) => setManager(e.target.value)} autoComplete="off" />
          </div> */}
        {(SYSTEM_TYPE == 'GURT' || SYSTEM_TYPE == 'SHOP') && 
          <div className="mb-3" style={{ zIndex: "100", position: "relative" }}>
            <CFormLabel htmlFor="exampleFormControlInput1">Менеджер*</CFormLabel>
            <AsyncSelect
              cacheOptions
              loadOptions={loadOptions}
              defaultOptions
              value={selectedManager}
              onChange={handleChangeManagerr}
              className="flex-grow-1"
              placeholder="Виберіть..."
              required
            // menuPortalTarget={document.body} //  DOM-елемент, в який буде відображено меню випадаючого списку. Цей компонент відображається на окремій сторінці accounts/create(update/id), то там {document.body} відображає нормально. Ще використовується в /orders/update/19466 для створення контрагента і відкритий список відображається під двома модальними вікнами, тому закоментував
            />
          </div>
        }

        <div className="mb-3" style={{ zIndex: "1", position: "relative" }}>

          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(myData != "") ? myData.content : ''}
            init={{
              height: 500,
              menubar: 'edit view insert format',
              plugins: [
                'advlist','autolink',
                'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks','fullscreen',
                'insertdatetime','media','table', 'help', 'code'
             ],
              toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
              content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
              font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            }}
          />
        </div>

        {
          (myData != "" && type == "accountPage") ?
            <CButton type="submit" color="success" title="Оновити контрагента">Оновити</CButton>
            :
            <CButton type="submit" color="success" title="Створити контрагента">Створити</CButton>
        }
        {
          (setShowModalCreateAccount != undefined) ?
            <CButton className="ms-3" color="secondary" onClick={() => setShowModalCreateAccount(false)} title="Закрити вікно для створення контрагента">Закрити</CButton>
            :
            <Link to={urlRouter} className="ms-3"><CButton type="submit" color="secondary" title="Повернутися на сторінку з списком контрагентів">Повернутися до списку</CButton></Link>
        }
      </CForm >
    </>
  );
}

export default AccountsFormComponents;