import React, { useEffect, useState } from 'react';
import { CCard, CCardBody, CCardHeader, CCol, CRow, CButton, CFormInput, CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody, CFormCheck } from '@coreui/react';
import { DataGrid } from '@mui/x-data-grid';
import { Link, useLoaderData } from 'react-router-dom';
import axios from "axios";
import { cilPen, cilPlus, cilTrash } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import AxiosInstance from 'src/components/Axios';
import AsyncSelect from 'react-select/async';
import useOpenAccordion from 'src/components/store/useOpenAccordion';

const urlApi = '/site_products';
const urlRouter = '/site-products';

const page = 1;
const itemsPerPage = 30;

export async function LoaderSiteProducts({ params }) {
  let res = await AxiosInstance.get(`${urlApi}?page=${page}`).then((response) => {
    return response;
  });
  return res;
}

const SiteProducts = () => {
  const { data } = useLoaderData();
  const [rows, setRows] = useState(false);
  const [rowCountState, setRowCountState] = React.useState(0);
  const [nameFilterSearch, setNameFilterSearch] = useState('');
  const [categoryFilterSearch, setCategoryFilterSearch] = useState(''); // id вибраної категорії для запиту
  const [articleFilterSearch, setArticleFilterSearch] = useState('');
  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const [disabled, setDisabled] = useState(true);
  // Вибрана категорія
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Відслідковувати стартове значення стану акордиону в залежності від розширення екрану
  const activeAccordionItem = useOpenAccordion();
  const activeItem = activeAccordionItem.activeItem;

  if (!rows) {
    setRowCountState(data['hydra:totalItems'])
    setRows(data['hydra:member']);
  }

  // Запит за продуктами (+фільтр)
  const getRowsTable = (p = 1) => {
    // let request = `&itemsPerPage=${itemsPerPage}`;
    let request = {
      page: p,
      itemsPerPage: itemsPerPage,
    };
    if (nameFilterSearch != '') {
      request['name'] = nameFilterSearch;
    };
    if (categoryFilterSearch != '') {
      request['category.id'] = categoryFilterSearch;
    };
    if (articleFilterSearch != '') {
      request['products.article'] = articleFilterSearch;
    };
    // console.log("request", request);
    AxiosInstance.get(urlApi, { params: request }).then((response) => {
      setRows(response.data['hydra:member']);
      setRowCountState(response.data['hydra:totalItems']);
    });
  };

  // Пагінація, наступна сторінка з товарами
  const handlePageChange = (page) => {
    getRowsTable(page + 1);
  };

  // Завантажити категорії
  const loadCategories = () => {
    return AxiosInstance.get('/categories', { headers: { 'accept': 'application/json' } }).then((response) => {
      return response.data.map((result) => ({
        label: result.name,
        value: result.id,
      }));
    });
  };

  // Вибрана категорія для відображення в полі пошуку та подальшій передачі для фільтревання товарів
  useEffect(() => {
    if (selectedCategory != null) {
      // console.log("selectedCategory", selectedCategory);
      setCategoryFilterSearch(selectedCategory.value);
    };
  }, [selectedCategory]);

  // Після вибору категорії зробити запит за товарами категорії та оновити товари для вибору
  useEffect(() => {
    getRowsTable();
  }, [categoryFilterSearch]);

  // Функція для вибору категорії з списку
  const handleChange = (selectedCategory) => {
    setSelectedCategory(selectedCategory);
  };

  // Заблокувати кнопку фільрації якщо не введені символи для пошуку
  const disabledButton = (event) => {
    if (event.target.value) {
      setDisabled(false);
    } else {
      setDisabled(true);
    };
  };

  // Якщо хоч одне поле пошуку заповнене, то розблокувати кнопку пошуку
  useEffect(() => {
    if (categoryFilterSearch != "" || nameFilterSearch != "" || articleFilterSearch) {
      setDisabled(false);
    };
  }, [categoryFilterSearch, nameFilterSearch, articleFilterSearch]);

  // ----- Start handleFilterChange -----
  const handleNameFilterChange = (event) => {
    setNameFilterSearch(event.target.value);
    disabledButton(event);
  };

  const handleArticleFilterChange = (event) => {
    setArticleFilterSearch(event.target.value);
    disabledButton(event);
  };
  // ----- End handleFilterChange -----

  // Фільтрування по name,barcode та артиклу
  const handleFilterSearch = (event) => {
    // console.log("handleFilterSearch event", event)
    event.preventDefault();
    getRowsTable();
  };

  // Скинути фільтр
  const handleFilterReset = () => {
    setNameFilterSearch("");
    setCategoryFilterSearch("");
    setSelectedCategory(null);
    setArticleFilterSearch("");
    setDisabled(true);
    getRowsTable();
  };


  function Deleted(row) {
    confirmAlert({
      title: 'Ви впевнені що хочите видалити цей пункт ?',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Так',
          onClick: () => {
            const response = AxiosInstance.delete(`${urlApi}/${row.id}`).then((response) => {
              getRowsTable();
            });
          }
        },
        {
          label: 'Ні',
          onClick: () => {
            return;
          }
        }
      ]
    });
  }


  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'image',
      headerName: 'Image',
      width: 75,

      editable: true,
      renderCell: (params) => {
        return (
          <img
            src={(params.row.siteProductsMedia != undefined && params.row.siteProductsMedia.length > 0) ?
              process.env.REACT_APP_SERVER_URL + '' + (params.row.mainSiteProductMedia != undefined ? params.row.mainSiteProductMedia.media.contentUrl : params.row.siteProductsMedia[0].media.contentUrl)
              : ''
            }
            style={{ height: '45px', width: 'auto' }}
          />
        )
      }
    },
    {
      field: 'name', headerName: 'Назва', width: 400,
      renderCell: (params) => {
        return (
          <Link
            to={`${urlRouter}/update/${params.row.id}`}
            style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.87)" }}
          >
            {params.row.name}
          </Link>
        );
      }
    },
    {
      field: 'categotise', headerName: 'Категорії', width: 400,
      renderCell: (params) => {
        let categoriesArr = [];
        params.row.category.forEach(item => {
          categoriesArr.push(item.name);
        });
        let categoriesStr = "";
        if (categoriesArr.length > 0) {
          categoriesStr = categoriesArr.join(", ");
        };
        return (
          <>
            {categoriesStr}
          </>
        );
      }
    },
    // { field: 'newsShot', headerName: 'Опис', width: 400 },
    // {
    //   field: 'created_at',
    //   headerName: 'Дата',
    //   type: 'date',
    //   width: 160,
    // },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      renderCell: (params) => {
        return <Link to={`${urlRouter}/update/${params.row.id}`}><CButton color="dark" variant="outline"><CIcon icon={cilPen} customClassName="nav-icon" height={20} /></CButton></Link>;
      }
    },
    {
      field: "delete",
      headerName: "Видалити",
      sortable: false,
      renderCell: (params) => {
        return <CButton color="danger" variant="outline" onClick={() => {
          Deleted(params.row)
        }
        }><CIcon icon={cilTrash} customClassName="nav-icon" height={20} /></CButton>;
      }
    },
  ];

  // Натиснення кнопки: "Enter" - виконати пошук; "Escape" - скинути пошук 
  const keyDown = (event) => {
    // Виконати пошук
    if (event.key === 'Enter' && !disabled) {
      event.preventDefault();
      handleFilterSearch(event);
    };
    // Скинути пошук
    if (event.key === 'Escape') {
      event.preventDefault();
      handleFilterReset(event);
    };
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CAccordion key={activeItem} activeItemKey={activeItem} flush>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader><strong>Фільтр</strong></CAccordionHeader>
              <CAccordionBody>
                <CRow className="mt-3 mx-2">
                  <CCol md={9} className="me-auto">
                    <CRow>
                      <CCol sm={6}>
                        <AsyncSelect
                          cacheOptions
                          loadOptions={loadCategories}
                          defaultOptions
                          value={selectedCategory}
                          onChange={handleChange}
                          className="flex-grow-1 me-1 mb-3"
                          onKeyDown={keyDown}
                          placeholder="Оберіть категорію"
                        />
                      </CCol>

                      <CCol md={3} className='filter-field'>
                        <CFormInput
                          placeholder="Назва" id="nameFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleNameFilterChange} value={nameFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                      <CCol md={3} className='filter-field'>
                        <CFormInput
                          placeholder="Артикул" id="articleFilterSearch" aria-label="Пошук" className="me-2"
                          onChange={handleArticleFilterChange} value={articleFilterSearch} onKeyDown={keyDown} autoComplete="off"
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol md={3} style={{ textAlign: "end" }}>
                    <CButton className="me-2" type="submit" color="secondary" onClick={handleFilterSearch} disabled={disabled}>Пошук</CButton>
                    <CButton type="submit" color="secondary" onClick={handleFilterReset}>X</CButton>
                  </CCol>
                </CRow>
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>


          <CCardHeader>
            <strong>Товари</strong> <small>відповідники товарів для сайту</small>
          </CCardHeader>
          <CCardBody>
            <p className="text-medium-emphasis small">
              <Link to={`${urlRouter}/create`}><CButton color="secondary"><CIcon icon={cilPlus} customClassName="nav-icon" height={15} />Створити</CButton></Link>
            </p>
            <div style={{ height: 600, width: '100%' }}>

              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={itemsPerPage}
                // rowsPerPageOptions={[30]}
                rowsPerPageOptions={[itemsPerPage]}
                pagination
                rowCount={rowCountState}
                paginationMode="server"
                onPageChange={handlePageChange}
              />
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default SiteProducts;
