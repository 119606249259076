import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AxiosInstance from "src/components/Axios";
import { PieChart } from '@mui/x-charts/PieChart';

const Home = ({user_data}) => {

    const [data, setData] = useState(false);
    const [metrics, setMetrics] = useState([]); 
    const isShop = data && data.roles.find(role => role.toLowerCase().includes("shop"));

    useEffect(()=>{
        if (user_data != '' && user_data != undefined) {
            setData(JSON.parse(user_data));
        }
    }, [user_data]);

    useEffect(()=> {
        AxiosInstance.get('/objects?pagination=false').then(response => {
            const objectMetric = {label: 'Objects', value: response.data["hydra:totalItems"] };

            AxiosInstance.get('/projects?pagination=false').then(response => {
                const projectMetric = {label: 'Projects', value: response.data["hydra:totalItems"] };

                AxiosInstance.get('/posts?pagination=false').then(response => {
                    const publicMetric = {label: 'Publications', value: response.data["hydra:totalItems"] };
                    setMetrics([objectMetric, projectMetric, publicMetric]);
                })
            })

        }).catch(err => {
            console.log(err);
        })
    }, [])


    return (
            <section className="home">
                {data && 
                    <div className="home__title">
                        <h1>Welcome, {data.first_name} {data.last_name}</h1>
                        <h5> {data.roles.map((role, index) => {
                            return <span key={index}>{role}{data.roles.length === (index + 1) ? "" : ","} </span> 
                        })}
                        </h5>
                </div>}
                {isShop 
                ?   <div className="home__action">
                        <div className="home__action-buttons">
                            <Link to={'/products/create'}>+ Add Product</Link>
                            <Link to={'/products/category'}>+ Add Category</Link>
                        </div>
                    </div>
                :   <>
                    <div className="home__action">
                            <div className="home__action-buttons">
                            <Link to={'/objects/create'}>+ Add Object</Link>
                            <Link to={'/post/articles/create'}>+ Write Publication</Link>
                            <Link to={'/projects/create'}>+ Create Project</Link>
                        </div>
                    </div>
                    <div className="home__metricts">
                    <h5>Metrics:</h5>
                        {metrics.length > 0 
                        ? <PieChart
                            series={[
                                {
                                data: metrics,
                                highlightScope: { fade: 'global', highlight: 'item' },
                                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                },
                            ]}
                            width={650}
                            height={400}
                            />
                        : <span className="loader">Loading metrics data</span>}
                    </div>
                </>}
            </section>
    )
}

export default Home;