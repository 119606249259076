import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  CForm,
  CFormLabel,
  CFormInput,
  CButton,
  CFormCheck
} from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import axios from "axios";
import PermissionsNoPage, { Permissions } from "src/components/Permissions";
import AxiosInstance from 'src/components/Axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import { Alert } from 'react-bootstrap';
import NewsMedia from 'src/components/news/NewsMedia';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PageInfoList from 'src/components/page-info/PageInfoList';
import MediaFrom from 'src/components/media/MediaFrom';
import LangugesPanel from './LangugesPanel';
import Select from 'react-select'

const urlRouter = '/post';
const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;
const urlApi = '/posts';
export async function LoaderBlogUpdate({ params }) {
  if (Object.keys(params).length != 0 && params.id != undefined) {
    let res = await AxiosInstance.get(`${urlApi}/${params.id}`).then((response) => {
      response['type'] = (params.type != undefined) ? params.type : "post";
      // console.log("posts/id/ get response", response);
      return response;
    });
    return res;
  };

  return { type: (params.type != undefined) ? params.type : 'post', data: undefined };
};


const formReducer = (state, event) => {
  return {
    ...state,
    [event.target.name]: event.target.value
  }
}

const blogForm = ({ user_data }) => {
  if (!Permissions(user_data, 'Blog', 'VIEW_BLOG')) {
    return <PermissionsNoPage />;
  }
  const { data, type } = useLoaderData();
  const [user, setUser] = useState(false);
  const [owners, setOwner] = useState((data?.owner != undefined) ? {label: getUserName(data.owner), value: data.owner.id} : {label: getUserName(user), value: user.id});
  const [ownersOptions, setOwnersOptions] = useState([]);

  const [name, setName] = useState((data != undefined) ? data.name : '');
  const [pageType, setPageType] = useState((type != undefined) ? type : 'post');

  const [dateEntered, setDateEntered] = useState((data != undefined && data.dateEntered != undefined) ? new Date(data.dateEntered).toISOString() : new Date().toISOString());
  const [dateModified, setDateModified] = useState((data != undefined && data.dateModified != undefined) ? new Date(data.dateModified).toISOString() : new Date().toISOString());
  const [currentMedia, setCurrentMedia] = useState((data != undefined) && data.mainMedia);
  const [language, setLaguage] = useState((data != undefined) ? data.language?.key : 'ua');
  const [pinnet, setPinnet] = useState((data?.pinnet != undefined) ? data.pinnet : false);
  const navigate = useNavigate();

  const popup = MyToastify();
  const editorRef = useRef(null);

  useEffect(() => {
    const user = localStorage.getItem('user_data');
    if (user !== null && user !== '') {
      setUser(JSON.parse(user));
    }
  }, [])

  useEffect(()=> {
    if(data?.owner === undefined) {
      setOwner({label: getUserName(user), value: user.id})
    }
  }, [user])

  function getUserName(data) {
    if(data?.firstName?.length > 0 || data?.firstName?.length > 0) {
      return  `${data.firstName || ""} ${data.lastName || ""}`; 
    }
    if(data?.first_name?.length > 0 || data?.last_name?.length > 0) {
      return  `${data.first_name || ""} ${data.last_name || ""}`; 
    }
    if(data?.username?.length > 0) {
      return data.username; 
    }
    if(data?.email > 0) {
      return data.username; 
    }
    if(data.id){
      return data.id
    }
    return "";
  }

  useEffect(() => {
    AxiosInstance.get('/users?pagination=false').then((response) => {
      const data = response.data['hydra:member'];
      // console.log(data);
      const ownerOptions = data.map(owner => {
        return { label: getUserName(owner), value: owner.id }
      })
      setOwnersOptions(ownerOptions);

    }).catch((err) => console.log(err));
  }, [])

  useEffect(() => {
    // setMyData(data)
    setPageType(data?.type || type)
    setDateEntered((data && data.dateEntered) ? new Date(data.dateEntered).toISOString() : new Date().toISOString())
    setDateModified((data && data.dateModified) ? new Date(data.dateModified).toISOString() : new Date().toISOString())

    setCurrentMedia(data?.mainMedia)

    setName(data?.name)
  }, [data]);
  const handleSubmit = event => {
    event.preventDefault();
    if (editorRef.current) {
      // console.log(editorRef.current.getContent());
      const article = {
        name: name,
        dateEntered: dateEntered,
        dateModified: dateModified,
        type: pageType,
        pinnet: pinnet,
        content: editorRef.current.getContent(),
        owner: `/api/users/${owners?.value}`
      };
      if (data != undefined) {
        article.language = data.language ? `/api/languages/${data.language.id}` : `/api/languages/1`;
        const response = AxiosInstance.put('/posts/' + data.id, article).then((response) => {
          popup.toastifySuccess('Пост оновлено');
          if (type == undefined || type == 'page' || type == '')
            return navigate(`${urlRouter}/update/${response.data.id}`);
          else
            return navigate(`/post/${type}/${response.data.id}`);
        });
      } else {
        article.language = `/api/languages/1`;
        const response = AxiosInstance.post('/posts', article).then((response) => {
          popup.toastifySuccess('Пост cтворено');
          if (type == undefined || type == 'post' || type == '')
            return navigate(`${urlRouter}/update/${response.data.id}`);
          else
            return navigate(`/post/${type}/${response.data.id}`);
        });
      }
    }
  }

  const datePicker = (value) => {
    return value.toISOString();
  }

  function changeMedia() {
    if (currentMedia === data?.mainMedia) {
      popup.toastifyWarning('Не завантажено нового зображення');
    }
    else {
      if (currentMedia && data?.mainMedia !== undefined) {
        const oldId = data.mainMedia.id;
        AxiosInstance.put(`/posts/${data.id}`, { mainMedia: currentMedia['@id'] }).then(() => {
          popup.toastifySuccess('Зображення оновлено');
          AxiosInstance.delete(`/media_objects/${oldId}`); // видалення старої прив'язки
        })
      } else if (currentMedia && data?.mainMedia === undefined) {
        AxiosInstance.put(`/posts/${data.id}`, { mainMedia: currentMedia['@id'] }).then(() => {
          popup.toastifySuccess('Зображення додано');
        })
      }
      else {
        AxiosInstance.put(`/posts/${data.id}`, { mainMedia: currentMedia['@id'] }).then(() => {
          popup.toastifySuccess('Зображення оновлено');
        })
      }
    }
  }
  // console.log('data', data)
  // console.log('type', type)
  return (
    <>
      <div className='col d-flex justify-content-end'>
        {data !== undefined &&
          <LangugesPanel data={data}
            // setMyData={setMyData}
            setLaguage={setLaguage}
            language={language} />}
      </div>
      {data !== undefined &&
        <div className='mb-3'>
          <MediaFrom type="materials" setNewMedia={setCurrentMedia} toastifySuccess={popup.toastifySuccess} />
          {currentMedia && <>
            <img className='object-img mt-3' width={300} height={300} src={process.env.REACT_APP_SERVER_URL + currentMedia.contentUrl} />
            {currentMedia?.id !== data?.mainMedia?.id && <CButton type="submit" onClick={changeMedia} className="my-3" color="success">Зберегти Зображення</CButton>} </>

          }
        </div>}
      <CForm onSubmit={handleSubmit}>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Назва</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Дата публікації</CFormLabel>
          <DatePicker
            className="form-control"
            dateFormat="yyyy-MM-dd"
            selected={new Date(dateEntered)}
            onChange={(date) => setDateEntered(datePicker(date))}
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput1">Дата модифікації</CFormLabel>
          <DatePicker
            className="form-control"
            dateFormat="yyyy-MM-dd"
            selected={new Date(dateModified)}
            onChange={(date) => setDateModified(datePicker(date))}
            disabled
          />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput3">Користувач (Автор)</CFormLabel>
          <Select
            value={owners}
            name="colors"
            options={ownersOptions}
            className="basic-select"
            classNamePrefix="select"
            onChange={(owner) => {
              setOwner(owner);
            }}
          />
          {/* <CFormInput type="text" id="exampleFormControlInput3" value={user ? `${user?.first_name} ${user?.last_name}` : ''} disabled /> */}
        </div>
        <div className="mb-3">
          <CFormCheck id="exampleFormControlInput8" label="Закріплено" checked={pinnet} onChange={() => setPinnet(!pinnet)} />
        </div>
        <div className="mb-3">
          <CFormLabel htmlFor="exampleFormControlInput2">Тип</CFormLabel>
          <CFormInput type="text" id="exampleFormControlInput2" value={pageType} onChange={(e) => setPageType(e.target.value)} autoComplete="off" disabled={(type == undefined || type == 'page') ? false : true} />
        </div>
        <div className="mb-3">
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(data != undefined) ? data.content : ''}
            init={{
              height: 500,
              menubar: 'edit view insert format',
              plugins: [
                'advlist', 'autolink',
                'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                'insertdatetime', 'media', 'table', 'help', 'code'
              ],
              toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
              content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
              font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            }}
          />
        </div>
        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>
      {(data != undefined) &&
        <PageInfoList id={data.id} type="posts" />
      }
      <ToastContainer />
    </>
  );
};

export default blogForm;
