import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { CForm, CFormLabel, CFormInput, CButton, CCard, CCardBody, CCardHeader, CCol, CRow, } from '@coreui/react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import AxiosInstance from 'src/components/Axios';
import { transliterate } from 'transliteration';
import SiteProductsCategories from 'src/components/site-products/SiteProductsCategories';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MyToastify from 'src/components/myComponents/MyToactify';
import ProductsForSiteProducts from 'src/components/site-products/ProductsForSiteProducts';
import SiteProductsMedia from 'src/components/site-products/SiteProductsMedia';

const urlApi = '/site_products';
const urlRouter = '/site-products/update';

export async function LoaderSiteProductsUpdate({ params }) {
  if (Object.keys(params).length != 0) {
    let res = await AxiosInstance.get(`${urlApi}/${params.pid}`).then((response) => {
      // console.log("get data response", response);
      return response;
    });
    return res;
  };
  return false;
};


const SiteProductForm = () => {
  const { data } = useLoaderData();
  // console.log('data', data);
  const [dataState, setDataState] = useState((data != undefined) ? data : false);
  // console.log('dataState', dataState);
  const [name, setName] = useState((dataState != false) ? dataState.name : '');
  const [slug, setSlug] = useState((dataState != false) ? dataState.slug : '');
  const [status, setStatus] = useState((dataState != false) ? dataState.status : '');
  const [price, setPrice] = useState((dataState != false) ? dataState.price : 0);
  // const [price, setPrice] = useState(0);
  const editorRef = useRef(null);
  // Ключ для Editor
  const apiKey = process.env.REACT_APP_TINYMCE_API_KEY;
  let navigate = useNavigate();

  // Викликає popup з власного компонента
  const popup = MyToastify();
  // Текст для popup
  const popupPostTextSuccess = "Товар збережено";
  const popupPutTextSuccess = "Товар оновлено";
  const popupTextError = "Помилка запиту";

  // Запит за доданими продукта
  const getData = async () => {
    AxiosInstance.get(`${urlApi}/${data.id}`).then((response) => {
      // console.log('get prod response', response);
      if (response.status == 200) {
        setDataState(response.data);
      } else {
        // console.log("Помилка запиту");
        popup.toastifyWarning(popupTextError);
      }
    });
  };

  // відслідковувати зміну dataState (при оновленні даних)
  useEffect(() => {
    // console.log('dataState', dataState);
    if (dataState != false && dataState.name != "") {
      setName(dataState.name);
      setPrice(dataState.price);
    }
  }, [dataState]);

  const handleSubmit = event => {
    event.preventDefault();
    let slugT;

    if (editorRef.current) {
      // console.log(editorRef.current.getContent());

      if (slug == '') {
        slugT = transliterate(name).replace(' ', '-');
        setSlug(slugT);
      } else {
        slugT = transliterate(slug).replace(' ', '-');
      }

      const article = {
        name: name,
        slug: slugT,
        status: status,
        price: parseFloat(price),
        description: editorRef.current.getContent(),
      };

      if (data != undefined) {
        AxiosInstance.put(`${urlApi}/${data.id}`, article).then((response) => {
          // console.log('put response', response);
          if (response.status == 200) {
            // Popup про успішні зміни
            popup.toastifySuccess(popupPutTextSuccess);
            // Оновити дані
            getData();
          } else {
            // console.log("Помилка запиту");
            popup.toastifyWarning(popupTextError);
          }
        });
      } else {
        AxiosInstance.post(urlApi, article).then((response) => {
          // console.log('post response', response);
          if (response.status == 201) {
            // Popup про успішні зміни
            popup.toastifySuccess(popupPostTextSuccess);
            return navigate(`${urlRouter}/${response.data.id}`)
          } else {
            // console.log("Помилка запиту");
            popup.toastifyWarning(popupTextError);
          }
        });
      };
    };
  };

  return (
    <>
      {/* Popup про успішні зміни (справа зверху) */}
      <ToastContainer />

      {/* Назва продукту та зображення */}
      {(dataState != false) &&
        <>
          <h1 className="mb-3">{dataState.name}</h1>
          <SiteProductsMedia dataState={dataState} getData={getData} toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} />
        </>
      }

      <CForm onSubmit={handleSubmit} className="mb-3">
        <div className="row">
          <div className="col-6">
            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">Назва Товару</CFormLabel>
              <CFormInput type="text" id="exampleFormControlInput1" value={name} onChange={(e) => setName(e.target.value)} autoComplete="off" />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput2">Синонім</CFormLabel>
              <CFormInput type="text" id="exampleForControlInput2" value={slug} onChange={(e) => setSlug(e.target.value)} autoComplete="off" />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">Статус</CFormLabel>
              <CFormInput type="text" id="exampleFormControlInput2" value={status} onChange={(e) => setStatus(e.target.value)} />
            </div>
          </div>
          <div className="col-6">
            <div className="mb-3">
              <CFormLabel htmlFor="exampleFormControlInput1">
                Ціна 
                {/* (найнижча ціна з доданих товарів) */}
              </CFormLabel>
              <CFormInput type="number" id="exampleFormControlInput2" value={price}
                onChange={(e) => setPrice(e.target.value)}
                // disabled 
              />
            </div>
          </div>
        </div>
        <div className="mb-3">
          <Editor
            apiKey={apiKey}
            onInit={(evt, editor) => editorRef.current = editor}
            initialValue={(dataState != false) ? dataState.description : ''}
            init={{
              height: 200,
              menubar: 'edit view insert format',
              plugins: [
                'advlist','autolink',
                'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks','fullscreen',
                'insertdatetime','media','table', 'help', 'code'
             ],
              toolbar: 'undo redo | fontfamily fontsize | fontselect | bold italic backcolor | ' +
                'alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist checklist outdent indent | removeformat | link media a11ycheck code table help',
              content_style: 'body { font-family:FixelDisplayRegular; font-size:14px }',
              font_family_formats: "Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Fixel Display Regular=fixelDisplayRegular; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
            }}
          />
        </div>
        <CButton type="submit" color="success">Зберегти</CButton>
      </CForm>

      {/* Категорії */}
      {(data != undefined) &&
        <>
          <hr />
          <SiteProductsCategories siteProduct_id={data.id} toastifySuccess={popup.toastifySuccess} />
        </>
      }

      {/* Додані товари */}
      {(data != undefined) &&
        <>
          <hr />
          <ProductsForSiteProducts
            siteProduct_id={data.id} dataState={dataState} getData={getData} setDataState={setDataState}
            toastifySuccess={popup.toastifySuccess} toastifyWarning={popup.toastifyWarning} setPrice={setPrice}
          />
        </>
      }
    </>
  );
};

export default SiteProductForm;
